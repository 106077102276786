import {
  buildCountryData,
  CountrySelectorDropdown,
  defaultCountries,
  FlagImage,
  ParsedCountry,
  usePhoneInput,
  UsePhoneInputConfig,
} from "react-international-phone";
import Input, { InputProps, InputStatus } from "../Input/Input";
import { forwardRef, MouseEventHandler, useEffect, useRef, useState } from "react";
import useCombinedRefs from "src/hooks/useCombinedRefs";
import styles from "./PhoneInput.css";
import selectStyles from "src/components/Select/Select.css";
import inputStyles from "src/components/Input/Input.css";
import clsx from "clsx";
import { Icon, IconType } from "@spartacommodities/sparta-react-core-components";
import { theme } from "src/theme";

type PhoneInputProps = {
  classes?: {
    root?: string;
    input?: InputProps["classes"];
  };
  value?: string;
  onChange?: UsePhoneInputConfig["onChange"];
  onClickCountrySelector?: () => void;
  onSelectCountry?: (country: ParsedCountry) => void;
} & Omit<InputProps, "ref" | "onChange" | "value" | "defaultValue" | "autoComplete">;

const EMPTY_COUNTRY = buildCountryData({
  areaCodes: undefined,
  dialCode: "",
  format: undefined,
  iso2: "",
  name: "Not selected",
  priority: undefined,
});

const COUNTRIES = [EMPTY_COUNTRY, ...defaultCountries];

const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  (
    {
      classes,
      value,
      onChange,
      helper,
      id,
      status,
      onClick,
      onClickCountrySelector,
      onSelectCountry,
      ...rest
    },
    forwardedRef
  ) => {
    const rootRef = useRef<HTMLDivElement>(null);
    const ref = useCombinedRefs<HTMLInputElement>(forwardedRef);

    const [isOpened, setIsOpened] = useState(false);

    const { inputValue, handlePhoneValueChange, country, setCountry, inputRef } = usePhoneInput({
      countries: COUNTRIES,
      value,
      defaultCountry: "gb",
      disableDialCodePrefill: true,
      onChange,
      inputRef: ref,
    });

    useEffect(() => {
      const blurHandler = (ev: MouseEvent) => {
        if (rootRef.current && !rootRef.current.contains(ev.target as Node)) {
          setIsOpened(false);
        }
      };

      window.addEventListener("click", blurHandler);

      return () => {
        window.removeEventListener("click", blurHandler);
      };
    }, []);

    const handleOnClickCountrySelector = () => {
      setIsOpened((prev) => !prev);
      onClickCountrySelector?.();
    };

    const handleOnSelectCountry = (newCountry: ParsedCountry) => {
      setIsOpened(false);
      setCountry(newCountry.iso2, { focusOnInput: true });
      onSelectCountry?.(newCountry);
    };

    const handleOnClickPhoneInput: MouseEventHandler<HTMLInputElement> = (ev) => {
      setIsOpened(false);
      onClick?.(ev);
    };

    let inputStatus = status;

    if (status === undefined) {
      inputStatus = isOpened ? InputStatus.ACTIVE : InputStatus.DEFAULT;
    }

    return (
      <div className={clsx(styles.root, isOpened && styles.active)} ref={rootRef}>
        <div className={styles.inputWrapper}>
          <button
            className={clsx(styles.countryButton, inputStatus === InputStatus.ERROR && styles.error)}
            type="button"
            onClick={handleOnClickCountrySelector}
          >
            {country.iso2 !== EMPTY_COUNTRY[1] ? (
              <FlagImage iso2={country.iso2} height="14px" width="21px" />
            ) : (
              <img alt="" src="/svgs/no-flag.svg" height="14px" width="21px" />
            )}
            <Icon type={IconType.ARROW_DOWN} fill={theme.colors.neutral.dark_45} height={14} width={21} />
          </button>
          <Input
            classes={{
              input: styles.input,
            }}
            id={id}
            autoComplete="off"
            value={inputValue}
            onChange={handlePhoneValueChange}
            onClick={handleOnClickPhoneInput}
            ref={inputRef}
            status={inputStatus}
            type="tel"
            pattern="^\+\d+\s.+$"
            aria-describedby={helper?.id}
            {...rest}
          />
        </div>
        <CountrySelectorDropdown
          className={selectStyles.list}
          listItemClassName={clsx(selectStyles.option, styles.option)}
          listItemCountryNameClassName={styles.optionText}
          listItemDialCodeClassName={styles.optionDial}
          countries={COUNTRIES}
          show={isOpened}
          onSelect={handleOnSelectCountry}
          selectedCountry={country.iso2}
        />
        {helper && (
          <span
            className={clsx(
              inputStyles.helperText,
              inputStyles[inputStatus || InputStatus.DEFAULT],
              classes?.input?.helperText
            )}
            id={helper?.id}
          >
            {helper?.text}
          </span>
        )}
      </div>
    );
  }
);

PhoneInput.displayName = "PhoneInput";

export default PhoneInput;
