import React, { ChangeEventHandler, forwardRef, useState } from "react";
import styles from "./Input.css";
import clsx from "clsx";
import useCombinedRefs from "src/hooks/useCombinedRefs";

export enum InputStatus {
  DEFAULT = "default",
  ACTIVE = "active",
  ERROR = "error",
  MODIFIED = "modified",
}

type HelperMessage = {
  id: string;
  text: string;
}

export type InputProps = Omit<React.ComponentPropsWithoutRef<"input">, "className" | "id"> & {
  classes?: {
    root?: string;
    input?: string;
    placeholder?: string;
    helperText?: string;
  };
  status?: InputStatus;
  id: string;
  helper?: HelperMessage;
};

const computeDefaultValue = (value: InputProps["value"]): NonNullable<InputProps["defaultValue"]> => {
  if (typeof value === "number") {
    return 0;
  }

  if (Array.isArray(value)) {
    return [];
  }

  return "";
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      children,
      classes,
      defaultValue,
      disabled,
      onChange: onChangeProp,
      placeholder,
      status = InputStatus.DEFAULT,
      value: valueProp,
      id,
      helper,
      ...rest
    },
    forwardedRef
  ) => {
    const ref = useCombinedRefs(forwardedRef);

    const isControlled = valueProp !== undefined;
    const hasDefaultValue = defaultValue !== undefined;

    const [internalValue, setInternalValue] = useState(hasDefaultValue ? defaultValue : computeDefaultValue(valueProp));

    const value = isControlled ? valueProp : internalValue;

    const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      onChangeProp?.(e);

      if (!isControlled) {
        setInternalValue(e.target.value);
      }
    };

    return (
      <div
        className={clsx(styles.root, classes?.root, disabled && styles.disabled, helper && styles.hasHelperText)}
      >
        <input
          className={clsx(styles.input, styles[status], classes?.input)}
          disabled={disabled}
          onChange={handleOnChange}
          ref={ref}
          value={value}
          id={id}
          placeholder=" "
          aria-describedby={helper?.id}
          aria-invalid={status === InputStatus.ERROR}
          {...rest}
        />
        {placeholder && (
          <label className={clsx(styles.placeholder, classes?.placeholder)} htmlFor={id} title={placeholder} />
        )}
        {helper && (
          <span className={clsx(styles.helperText, styles[status], classes?.helperText)} id={helper?.id}>
            {helper?.text}
          </span>
        )}
        {children}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
