import { Spinner, theme } from "@spartacommodities/sparta-react-core-components";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import type { ActionFunctionArgs, LoaderFunctionArgs } from "react-router-dom";
import { Navigate, redirect, useFetcher, useRouteError, useRouteLoaderData, useSearchParams } from "react-router-dom";
import { datadogRumEnd, datadogRumInit, setDatadogUserInfo } from "src/utils/datadogRumInit.ts";
import Input, { InputStatus } from "../../components/Input/Input.tsx";
import PhoneInput from "../../components/PhoneInput/PhoneInput.tsx";
import PopUp from "../../components/PopUp/PopUp.tsx";
import Select from "../../components/Select/Select.tsx";
import { REGIONS } from "../../data";
import {
  CHECKBOX1_LINK,
  CHECKBOX1_MESSAGE,
  CHECKBOX2_MESSAGE,
  CHECKBOX2_MESSAGE_SUFFIX,
  CHECKBOX_LOWER_DESCRIPTION,
  PRIVACY_POLICY_LINK,
  TERMS_OF_USE_TITLE,
} from "../../literals.ts";
import Checkbox from "./components/Checkbox/Checkbox.tsx";
import TermsOfUse from "./components/TermsOfUse/TermsOfUse.tsx";
import { trackCheckboxChange, trackOnClickTermsAndConditions, trackSelectChange } from "./utils/inputTrack.ts";
import fetcher from "src/utils/fetcher.ts";
import type { IpAPIDTO } from "src/types";
import { getDefaultDataFromQueryParams } from "./utils/getDefaultDataFromQueryParams.ts";
import { segment, trackFieldClicked, trackFieldFilled, trackOpenPrivacyPolicy, trackSubmitForm } from "src/segment";
import validateForm from "./utils/validateForm.ts";
import postData from "./utils/postData.ts";

import { button, link, loadingButton, primaryButton, secondaryLink } from "src/styles.css.ts";
import * as styles from "./SignUp.css.ts";
import { useFeatureFlag } from "../../hooks/useFeatureFlag.ts";

export function SignupErrorBoundary() {
  const [searchParams] = useSearchParams();
  const { userData } = useRouteError() as { userData: Record<string, string> };

  if (searchParams.size === 0) {
    return <Navigate to={{ pathname: "/error", search: new URLSearchParams(userData).toString() }} />;
  }
  return <Navigate to={{ pathname: "/error", search: searchParams.toString() }} />;
}

export async function signupLoader({ request }: LoaderFunctionArgs) {
  const { country_calling_code } = await fetcher<IpAPIDTO>("https://ipapi.co/json/");

  const initialData = getDefaultDataFromQueryParams(request);

  return { country_calling_code, initialData };
}

export async function signupAction({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const { errors: errorForm, formState } = validateForm(formData);

  if (errorForm !== undefined) return { errors: errorForm, formState };

  if (formState?.email !== null) {
    // This is a test to check if we can decrease the amount of anonymous IDs.
    segment.load();
    segment.identify(formState?.email, {
      firstName: formState?.firstName,
      lastName: formState?.lastName,
      email: formState?.email,
    });
    setDatadogUserInfo(formState.email);
  }

  trackSubmitForm(formState);

  const { ok, errors } = await postData(formState);

  if (ok && errors === null) {
    throw redirect("/success");
  }

  return { errors, formState };
}

export default function SignUp() {
  const popUpRef = useRef<HTMLDialogElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (formRef.current === null) return;

    // check validity on initial load,
    // for eg. parameters
    setIsValid(formRef.current.checkValidity());
  }, []);

  // Datadog
  const canRunDatadog = useFeatureFlag("fe_signup_form_datadog_usage");
  const termsAndConditionsFF = useFeatureFlag("fe_signup_terms_and_conditions_location");

  // if flag is not enabled signup should continue showing the T&C as before
  const showTermsAndConditions = termsAndConditionsFF === null || termsAndConditionsFF === "signup";

  useEffect(() => {
    if (typeof canRunDatadog === "boolean" && canRunDatadog) datadogRumInit();
    else datadogRumEnd();
  }, [canRunDatadog]);
  // End - Datadog

  const { country_calling_code, initialData } = useRouteLoaderData("signup") as Awaited<
    ReturnType<typeof signupLoader>
  >;

  const fetcher = useFetcher();
  const { errors, formState } = (fetcher.data as Awaited<ReturnType<typeof signupAction>> | undefined) || {};

  return (
    <div className={styles.signupContainer} data-testid="signup-page">
      <section className={styles.sectionContainer}>
        <header className={styles.logo}>
          <img alt="Sparta" src="/svgs/onboarding-header.svg" />
        </header>

        <article className={styles.article}>
          <div className={styles.message}>
            <p className={styles.title}>Experience Sparta Market Outlook free for 21 days!</p>
            <p className={styles.description} data-testid="description">
              Sign up today and enjoy a 21-day free trial with full access to all the features that have made us a
              trusted choice for traders worldwide. No risk and instant access. Start your free trial today!
            </p>
          </div>

          <fetcher.Form
            method="POST"
            noValidate
            ref={formRef}
            className={styles.formContainer}
            onChange={(e) => {
              if (e?.currentTarget instanceof HTMLFormElement) {
                setIsValid(e.currentTarget.checkValidity());
              }
            }}
          >
            <div className={styles.formContent}>
              <div className={styles.inputContent}>
                <div className={styles.formRow}>
                  <input name="primaryProduct" defaultValue={initialData.primaryProduct ?? 14} hidden />
                  <input name="userRole" defaultValue={initialData.userRole ?? 12} hidden />

                  <Input
                    placeholder="First Name"
                    name="firstName"
                    id="firstName"
                    required
                    defaultValue={initialData?.firstName || formState?.firstName || undefined}
                    onBlur={() => trackFieldFilled({ inputName: "First Name", inputValue: formState?.firstName || "" })}
                    onClick={() => trackFieldClicked("First Name")}
                    status={errors?.firstName ? InputStatus.ERROR : InputStatus.DEFAULT}
                    helper={{ id: "firstName-message", text: errors?.firstName || " " }}
                  />

                  <Input
                    placeholder="Last Name"
                    name="lastName"
                    id="lastName"
                    required
                    defaultValue={initialData.lastName || formState?.lastName || undefined}
                    onBlur={() => trackFieldFilled({ inputName: "Last Name", inputValue: formState?.lastName || "" })}
                    onClick={() => trackFieldClicked("Last Name")}
                    status={errors?.lastName ? InputStatus.ERROR : InputStatus.DEFAULT}
                    helper={{ id: "lastName-message", text: errors?.lastName || " " }}
                  />
                </div>

                <div className={styles.formRow}>
                  <Input
                    autoComplete="email"
                    placeholder="Email"
                    name="email"
                    id="email"
                    helper={{ id: "email-message", text: errors?.email || " " }}
                    type="email"
                    required
                    onBlur={(event) => {
                      setDatadogUserInfo(event.currentTarget.value);
                      trackFieldFilled({ inputName: "Email", inputValue: formState?.email || "" });
                    }}
                    onClick={() => trackFieldClicked("Email")}
                    defaultValue={initialData.email || formState?.email || undefined}
                    status={errors?.email || errors?.email ? InputStatus.ERROR : InputStatus.DEFAULT}
                  />

                  <PhoneInput
                    placeholder="Phone"
                    name="phoneNumber"
                    id="phoneNumber"
                    required
                    onBlur={() => trackFieldFilled({ inputName: "Mobile", inputValue: formState?.phoneNumber || "" })}
                    onClick={() => trackFieldClicked("Mobile")}
                    onClickCountrySelector={() => trackFieldClicked("Country")}
                    onSelectCountry={(country) => trackFieldFilled({ inputName: "Country", inputValue: country.name })}
                    value={initialData.phoneNumber || formState?.phoneNumber || country_calling_code}
                    status={errors?.phoneNumber ? InputStatus.ERROR : undefined}
                    helper={{ id: "phoneNumber-message", text: errors?.phoneNumber || " " }}
                  />
                </div>

                <div className={styles.formRow}>
                  <Select
                    type="text"
                    placeholder="Region"
                    name="region"
                    id="region"
                    required
                    value={initialData.region || formState?.region || undefined}
                    status={errors?.region ? InputStatus.ERROR : InputStatus.DEFAULT}
                    helper={{ id: "region-message", text: errors?.region || " " }}
                    options={REGIONS}
                    onChange={(newRegion) => trackSelectChange({ value: newRegion, name: newRegion })}
                    onClick={() => trackFieldClicked("Region")}
                  />

                  <Input
                    type="text"
                    placeholder="Company Name"
                    name="companyName"
                    id="companyName"
                    required
                    onBlur={() =>
                      trackFieldFilled({
                        inputName: "Company Name",
                        inputValue: formState?.companyName || "",
                      })
                    }
                    onClick={() => trackFieldClicked("Company Name")}
                    defaultValue={initialData?.companyName || formState?.companyName || undefined}
                    status={errors?.companyName ? InputStatus.ERROR : InputStatus.DEFAULT}
                    helper={{ id: "companyName-message", text: errors?.companyName || " " }}
                  />
                </div>
              </div>
              <div className={styles.checkboxesContent}>
                <Checkbox
                  name="subscribeNewsletter"
                  id="subscribeNewsletter"
                  defaultChecked={initialData.subscribeNewsletter || formState?.subscribeNewsletter}
                  onChange={trackCheckboxChange("subscribeNewsletter")}
                >
                  <p className={clsx(styles.text, styles.checkboxLabel)}>
                    {CHECKBOX1_MESSAGE}
                    <a
                      className={clsx(link, secondaryLink, styles.text, styles.checkboxLabel)}
                      href={PRIVACY_POLICY_LINK}
                      target="_blank"
                      rel="noreferrer"
                      onClick={trackOpenPrivacyPolicy}
                    >
                      {CHECKBOX1_LINK}
                    </a>
                  </p>
                </Checkbox>
                {showTermsAndConditions && (
                  <Checkbox
                    name="termsAndConditions"
                    id="termsAndConditions"
                    required
                    defaultChecked={initialData.termsAndConditions || formState?.termsAndConditions}
                    onChange={trackCheckboxChange("termsAndConditions")}
                  >
                    <div className={clsx(styles.text, styles.checkboxLabel, styles.sameRow)}>
                      <button
                        className={clsx(button, link, secondaryLink, styles.text, styles.checkboxLabel)}
                        type="button"
                        onClick={() => trackOnClickTermsAndConditions(popUpRef)}
                      >
                        {CHECKBOX2_MESSAGE}
                      </button>
                      <p>&nbsp;{CHECKBOX2_MESSAGE_SUFFIX}</p>
                    </div>
                  </Checkbox>
                )}
                <p className={clsx(styles.text, styles.underCheckboxDescription)} data-testid="disclaimer">
                  {CHECKBOX_LOWER_DESCRIPTION}
                </p>
              </div>
            </div>

            <button
              className={clsx(button, primaryButton, styles.submitButton, fetcher.state !== "idle" && loadingButton)}
              type="submit"
              name="signup"
              disabled={!isValid}
              aria-disabled={!isValid}
            >
              {fetcher.state !== "idle" ? (
                <Spinner width={23} height={23} backgroundColor={theme.colors.neutral.dark_00} />
              ) : (
                "Sign up"
              )}
            </button>
          </fetcher.Form>

          <PopUp ref={popUpRef} title={TERMS_OF_USE_TITLE}>
            <TermsOfUse />
          </PopUp>
        </article>
      </section>
    </div>
  );
}
