import { AnalyticsBrowser } from "@segment/analytics-next";
import type { AnalyticsBrowserSettings, EventProperties, InitOptions } from "@segment/analytics-next";

const { MODE, VITE_APP_SEGMENT_WRITE_KEY } = import.meta.env;
const isProdEnv = MODE === "production";

export let analytics: AnalyticsBrowser | undefined;

export const load = (settings: Omit<AnalyticsBrowserSettings, "writeKey"> = {}, options: InitOptions = {}) => {
  if (!isProdEnv) return;

  analytics = AnalyticsBrowser.load({ ...settings, writeKey: VITE_APP_SEGMENT_WRITE_KEY }, options);
};

export const track = (name: string, rawPayload?: EventProperties) => {
  const payload = {
    ...rawPayload,
    environment: MODE,
  };

  if (!isProdEnv)
    return console.log(`Environment not enabled. Would be logged "${name}" event with this payload:`, payload);

  if (analytics) return analytics?.track(name, payload).catch((e) => console.error("Error logging track event", e));

  return console.error("Segment not initialized");
};

export const pageView = () => {
  if (!isProdEnv) return console.log('Environment not enabled. Would be logged "Loaded a page" event');

  if (analytics) return analytics?.page().catch((e) => console.error("Error logging page view", e));

  return console.error("Segment not initialized");
};
/**
 * # Segment Identify
 * The Segment Identify call lets you tie a user to their actions and record traits about them. It includes a unique User ID and any optional traits you know about the user, like their email, name, and more.
 *
 * *Segment recommends that you make an Identify call:*
 *
 * - After a user first registers
 * - After a user logs in
 * - When a user updates their info (for example, they change or add a new address)
 *
 * The first three examples are pretty self-explanatory, but many might ask: why you would call Identify on every page load if you’re storing the userId in the cookie/local storage?
 *
 * Calling Identify in one of Segment’s libraries is one of the first steps to getting started with Segment. Refer to library-specific documentation for more details.
 * @param props
 * @see https://segment.com/docs/connections/spec/identify/
 */
export const identify = (...props: Parameters<AnalyticsBrowser["identify"]>) => {
  // if (!isProdEnv) return console.log('Environment not enabled. Would be logged "Identify" event');

  if (!analytics) return console.error("Segment not initialized");

  analytics?.identify(...props);
};
