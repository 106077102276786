import axios, { AxiosResponse } from "axios";
import type { FormState, PostUserSignupResponse } from "./types";

const { VITE_APP_API_URL } = import.meta.env;

const endpoint = "/auth/v3/user_signup";

const HEADERS = {
  headers: {
    "Content-Type": "application/json",
  },
};

export default function postNewUserData(userData: FormState): Promise<AxiosResponse<PostUserSignupResponse>> {
  try {
    const url = `${VITE_APP_API_URL}${endpoint}`;
    return axios.post<PostUserSignupResponse>(url, userData, HEADERS);
  } catch (error) {
    console.log("There was an error signing up the user", error);
    throw error;
  }
}
