import { createRoot } from "react-dom/client";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import SignUp, { signupAction, SignupErrorBoundary, signupLoader } from "./routes/signUp/SignUp.tsx";
import { LD_GLOBAL_CONFIG } from "./constants";
import { StrictMode } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { SegmentPageView } from "./components/SegmentPageView/SegmentPageView.tsx";
import { pageContainer, pageContent } from "./routes/signUp/SignUp.css.ts";
import Header from "./components/Header/Header.tsx";
import Success from "./routes/success/Success.tsx";
import Error from "./routes/error/Error.tsx";

const { VITE_APP_LD_CLIENT_ID } = import.meta.env;

const router = createBrowserRouter([
  {
    path: "/",
    ErrorBoundary: Error,
    children: [
      {
        index: true,
        id: "signup",
        action: signupAction,
        loader: signupLoader,
        ErrorBoundary: SignupErrorBoundary,
        element: <SignUp />,
      },
      {
        path: "success",
        element: <Success />,
      },
      {
        path: "error",
        element: <Error />,
      },
    ],
  },
]);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    timeout: 5,
    clientSideID: VITE_APP_LD_CLIENT_ID,
    context: LD_GLOBAL_CONFIG,
    reactOptions: {
      useCamelCaseFlagKeys: false, // keep flag keys in snake_case
    },
  });

  const rootEl = document.getElementById("root");
  if (rootEl) {
    const root = createRoot(rootEl);

    root.render(
      <StrictMode>
        <LDProvider>
          <SegmentPageView>
            <main className={pageContainer}>
              <Header />
              <div className={pageContent}>
                <RouterProvider router={router} />
              </div>
            </main>
          </SegmentPageView>
        </LDProvider>
      </StrictMode>
    );
  }
})();
