// FORM TEXTS
export const CHECKBOX1_MESSAGE =
  "Please send me occasional emails about Sparta's news and services. You can unsubscribe at any time. ";
export const CHECKBOX1_LINK = "Privacy policy.";
export const PRIVACY_POLICY_LINK = "https://www.spartacommodities.com/privacy-policy/";
export const CHECKBOX2_MESSAGE = "Terms and conditions";
export const CHECKBOX2_MESSAGE_SUFFIX = "*";
export const CHECKBOX_LOWER_DESCRIPTION =
  "By clicking 'Sign up' below, you consent to Sparta storing and processing your personal information submitted above in order to provide the requested content.";
export const TERMS_OF_USE_TITLE = "Market Outlook Data Terms";

// INFORM STATUS OF REQUEST TEXTS
export const SUCCESS_TITLE = "Thank you!";
export const SUCCESS_SUBTITLE = "Welcome to Sparta Market Outlook";
export const SUCCCESS_DESCRIPTION =
  "Thank you for registering. Your username and password have been sent to the email address provided, along with a separate welcome email containing instructions on how to get started. If you do not receive your username and password within the next 15 minutes, please contact us at ";
export const SUCCESS_BUTTON = "Go to Log in";

export const ERROR_TITLE = "We're sorry";
export const ERROR_SUBTITLE = "something went wrong while attempting to process your registration";
export const ERROR_DESCRIPTION =
  "We have encountered an error while creating your account. Please try again. If the error persists, please contact ";
export const ERROR_BUTTON = "Try again";

// CONTACT INFORMATION
export const SPARTA_LINK_MESSAGE = "success@spartacommodities.com";
export const SPARTA_MAIL_LINK = "mailto:success@spartacommodities.com";

// ERRORS
export const EMAIL_ERROR = "Email must be formatted correctly.";
export const EMAIL_PROVIDER_FORBIDDEN =
  "Your email must be from a valid domain. Free providers (certain providers, including free providers like gmail, yahoo, hotmail, etc, are not permitted)";
export const EMAIL_COMPETITOR_FORBIDDEN =
  "The email provided is not permitted. Please contact sales@spartacommodities.com.";
export const PHONE_RANGE_ERROR = "The number you entered is invalid. Please review the number and try again.";
