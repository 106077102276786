import { useRef } from "react";
import type { PropsWithChildren } from "react";
import { analytics } from "src/segment/handlers.ts";
import * as segment from "src/segment/handlers.ts";

export function SegmentPageView({ children }: PropsWithChildren) {
  const executed = useRef(false);

  if (!executed.current) {
    if (analytics) {
      console.log("Page view");
      segment.pageView();
    }
    executed.current = true;
  }

  return children;
}
